import "./slider-services.scss";

import { Link } from "gatsby";
import SanityImage from "gatsby-plugin-sanity-image";
import React from "react";
import { useTranslation } from "react-i18next";
import ScrollContainer from "react-indiana-drag-scroll";

const SliderServices: React.FC<any> = (props: { services: [] }) => {
  const items = [];
  props.services.edges.forEach((service, index) => {
    if (
      !service.node.card ||
      service.node.card.title === null ||
      !service.node.has_ready
    )
      return;
    items.push(
      <Link to={`/${service.node.slug.es_AR}/`} key={index}>
        <div
          className={`card-service ${service.node.has_ready ? "" : "test"}  `}
        >
          {service.node.card.img && (
            <SanityImage
              {...service.node.card.img}
              width={680}
              alt={service.node.card.title.es_AR[0].children[0].text}
            />
          )}

          <h3 className={"card-service__title"}>
            {" "}
            {service.node.card.title.es_AR[0].children[0].text}{" "}
          </h3>
        </div>
      </Link>
    );
  });
  return (
    <div className={"o-slider-services"}>
      <ScrollContainer className="scroll-container">
        <div className={"scroll-container__content"}>{items}</div>
      </ScrollContainer>
    </div>
  );
};

export default SliderServices;
