import { Script } from "gatsby";
import * as React from "react";
import { PropsWithChildren } from "react";

import { logo } from "../../../data/config";

interface PropsMarkupLocation extends PropsWithChildren<any> {
  location: string;
  slug: string;
  image: string;
  cp: string;
  streetAddress: string;
  phone: string;
  country: string;
}

const LocationMarkupSchema: React.FC<PropsMarkupLocation> = (
  props: PropsMarkupLocation
) => {
  const markup = `{
  "@context": "https://schema.org",
  "@type": "ProfessionalService",
  "name": "C&R Seguridad ${props.location}",
  "image": "${logo}",
  "url": "https://www.cyrseguridad.com.ar/${props.slug}",
  "email": "info@cyrseguridad.com",
  "telephone": "${props.phone}",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "${props.streetAddress}",
    "addressLocality": "${props.location}",
    "postalCode": "${props.cp}",
    "addressCountry": "${props.country}"
  }  
}`;

  return (
    <Script strategy={"post-hydrate"} type="application/ld+json">
      {markup}
    </Script>
  );
};

export default LocationMarkupSchema;
