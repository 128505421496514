import BlockContent from "@sanity/block-content-to-react";
import Layout from "components/common/layout/layout";
import { SEO } from "components/common/SEO";
import { graphql } from "gatsby";
import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import ActionRatio from "../components/actionRatio/ActionRatio";
import LocationMap from "../components/actionRatioInCity/LocationMap";
import LocationMarkupSchema from "../components/common/SEO/LocationMarkupSchema";
import Gallery from "../components/gallery/Gallery";
import HeroPage from "../components/heroPage/HeroPage";
import SliderSegments from "../components/sliderSegments/SliderSegments";
import SliderServices from "../components/sliderServices/SliderServices";

const PageCity = ({ pageContext, data }) => {
  const {
    allSanitySegment: allSegments,
    sanityCity: city,
    allSanityService: services,
    sanityGallery: globalGallery,
  } = data;

  let path = `${city.slug.es_AR}/`;
  if (city.is_city) {
    path = `${city.region.slug.es_AR}/${city.slug.es_AR}/`;
  }
  return (
    <Layout>
      <section className={"is-z1"}>
        <HeroPage hero={city.hero} />
      </section>
      <section className={"p-vertical-l"}>
        <div className="container grid-x2 grid-x2--center m-vertical">
          <section>
            <h1 className={"a-title a-title--body a-underline--left"}>
              {city.content.title.es_AR[0].children[0].text}
            </h1>
            <BlockContent blocks={city.content.desc._rawEsAr} />
            <br />
            <AnchorLink
              href="#contact-form"
              className={"a-button a-button--arrow p-vertical"}
            >
              Saber mas
            </AnchorLink>
          </section>
          <div className="action-ratio-location">
            <LocationMap
              latLong={[city.location.lat, city.location.lng]}
              isCity={city.is_city}
            />
          </div>
        </div>
      </section>
      {/* <Companies is_backLight={true} /> */}
      <section className="services">
        <div className="container">
          <h2 className={"a-title a-title--body"}>
            Servicios <strong>C&R Seguridad</strong>
          </h2>
        </div>
        <SliderServices services={services} />
      </section>
      {city.map && <ActionRatio />}

      <Gallery photos={globalGallery.gallery} />
      <SliderSegments segments={allSegments} />
    </Layout>
  );
};

export default PageCity;

export const Head = ({ data }) => (
  <>
    <SEO
      title={`${data.sanityCity.seo.title.es_AR}`}
      description={`${data.sanityCity?.seo?.desc?.es_AR}`}
      robots="index, follow"
      canonical={
        data.sanityCity.is_city
          ? `${data.sanityCity.region.slug.es_AR}/${data.sanityCity.slug.es_AR}/`
          : `${data.sanityCity.slug.es_AR}/`
      }
    />
    <LocationMarkupSchema
      location={data.sanityCity.title}
      slug={
        data.sanityCity.is_city
          ? `${data.sanityCity.region.slug.es_AR}/${data.sanityCity.slug.es_AR}/`
          : `${data.sanityCity.slug.es_AR}/`
      }
      image={data.sanityCity.hero.img.asset.gatsbyImageData.images.fallback.src}
      cp={data.sanityCity.cp}
      streetAddress={data.sanityCity.streetAddress}
      phone={data.sanityCity.phone}
      country={data.sanityCity.country}
    />
  </>
);

export const query = graphql`
  query CitiesInCities($id: String = "-51742edf-8900-5c28-9b6d-d1a9d4704066") {
    sanityGallery(name: { regex: "/global/i" }) {
      gallery {
        asset {
          gatsbyImageData
          id
          _id
        }
      }
    }
    sanityCity(id: { eq: $id }) {
      location {
        lat
        lng
      }
      hero {
        img {
          _key
          _type
          asset {
            _id
            id
            gatsbyImageData(
              width: 1197
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        desc {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
        alt {
          es_AR
          es_AR
        }
        title {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
      }
      content {
        title {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
        desc {
          _rawEsAr
          es_AR {
            children {
              text
            }
          }
        }
      }
      gallery {
        asset {
          gatsbyImageData
          id
          _id
        }
      }
      title
      map {
        title {
          es_AR
          es_AR
        }
        map {
          ...ImageWithPreview
        }
        cities {
          has_ready
          title
          slug {
            es_AR
            es_AR
          }
        }
      }
      cp
      country
      streetAddress
      phone
      slug {
        es_AR
        es_AR
      }
      is_city
      region {
        slug {
          es_AR
          es_AR
        }
      }
      seo {
        title {
          es_AR
          es_AR
        }
        desc {
          es_AR
          es_AR
        }
        canonical {
          es_AR
          es_AR
        }
        robots
      }
    }
    allSanitySegment(filter: { id: { nin: [$id] } }) {
      edges {
        node {
          has_ready
          slug {
            es_AR
            es_AR
          }
          card {
            title {
              es_AR {
                children {
                  text
                }
              }
            }
            img {
              asset {
                gatsbyImageData
                id
                _id
              }
            }
          }
        }
      }
    }
    allSanityService {
      edges {
        node {
          has_ready
          slug {
            es_AR
            es_AR
          }
          card {
            title {
              es_AR {
                children {
                  text
                }
              }
            }
            img {
              asset {
                gatsbyImageData
                id
                _id
              }
            }
          }
        }
      }
    }
  }
`;
