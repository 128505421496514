import "./action-ratio-in-city.scss";

import React, { Suspense, useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const Leaflet = React.lazy(() => import("./leaflet"));


const LocationMap = (props: { latLong: [number, number], isCity: boolean }) => {
  const isSSR = typeof window === "undefined"; // hack to ssr and lazy
  let latLng: [number, number] = [-24.2760948, -54.4244685];
  if (props.latLong) {
    latLng = props.latLong;
  }

  return (
    <>
      <Helmet>
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
          integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
          crossOrigin=""
        />
      </Helmet>
      <div id={"mapid"}></div>
      {!isSSR && (
        <Suspense fallback={null}>
          <Leaflet latLng={latLng} isCity={props.isCity}/>
        </Suspense>
      )}
    </>
  );
};

export default LocationMap;
